export const SIDEBAR_POSITION = {
  LEFT: "left",
  RIGHT: "right",
};

export const SIDEBAR_BEHAVIOR = {
  STICKY: "sticky",
  FIXED: "fixed",
  COMPACT: "compact",
};

export const LAYOUT = {
  FLUID: "fluid",
  BOXED: "boxed",
};

export const THEME = {
  DEFAULT: "default",
  COLORED: "colored",
  DARK: "dark",
  LIGHT: "light",
};

export const THEME_PALETTE_LIGHT = {
  primary: "#3B82EC",
  "primary-dark": "#1659c7",
  "primary-light": "#84aef2",
  secondary: "#495057",
  success: "#4BBF73",
  info: "#1F9BCF",
  warning: "#f0ad4e",
  danger: "#d9534f",
  white: "#fff",
  "gray-100": "#f4f7f9",
  "gray-200": "#e2e8ee",
  "gray-300": "#dee6ed",
  "gray-400": "#ced4da",
  "gray-500": "#adb5bd",
  "gray-600": "#6c757d",
  "gray-700": "#495057",
  "gray-800": "#020202",
  "gray-900": "#212529",
  black: "#000",
};

export const THEME_PALETTE_DARK = {
  ...THEME_PALETTE_LIGHT,
  "primary-dark": "#84aef2",
  "primary-light": "#1659c7",
  white: "#293042",
  "gray-100": "#3e4555",
  "gray-200": "#545968",
  "gray-300": "#696e7b",
  "gray-400": "#7f838e",
  "gray-500": "#9498a1",
  "gray-600": "#a9acb3",
  "gray-700": "#bfc1c6",
  "gray-800": "#d4d6d9",
  "gray-900": "#eaeaec",
  black: "#fff",
};
export const schedule_options = [
  // { value: "Every 30 minutes", label: "Every 30 minutes" },
  { value: "Hourly", label: "Hourly" },
  // { value: "Every 3 hours", label: "Every 3 hours" },
  // { value: "Every 6 hours", label: "Every 6 hours" },
  { value: "Every 12 hours", label: "Every 12 hours" },
  { value: "Daily", label: "Daily" },
  { value: "Manual Cron", label: "Manual Cron" },
];

export const propagation_options = [
  { value: "ignore", label: "Ignore" },
  { value: "propagate_columns", label: "Propagate columns" },
  { value: "propagate_fully", label: "Propagate all changes" },
];
const config_ = {
  prod: {
    frontegg: {
      BASE_URL: process.env.REACT_APP_FRONTEGG_BASE_URL,
      CLIENT_ID: process.env.FRONTEGG_CLIENT_ID_PROD,
    },
    url: {
      GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      WORKFLOWS: process.env.REACT_APP_API_URL + "/workflows",
      AIRFLOW: process.env.REACT_APP_API_URL + "/airflow",
      OBSERVABILITY: process.env.REACT_APP_API_URL + "/observability",
      SQL_EDITOR_URL: process.env.REACT_APP_API_URL + "/sql_editor",
      GIT_URL: process.env.REACT_APP_API_URL + "/git",
      TAPS_URL: process.env.REACT_APP_API_URL + "/taps",
      WEBAPP: process.env.REACT_APP_API_URL + "/webapp",
      METADATA: process.env.REACT_APP_API_URL + "/metadata",
      PUSH_URL: process.env.REACT_APP_API_URL + "/push",
      DWH_URL: process.env.REACT_APP_API_URL + "/dwh",
    },
  },
  dev: {
    frontegg: {
      BASE_URL: "https://dev-datajolt.frontegg.com",
      CLIENT_ID: process.env.FRONTEGG_CLIENT_ID_DEV,
    },
    url: {
      AIRFLOW: "http://127.0.0.1:5000",
      WORKFLOWS: "http://127.0.0.1:5000",
      WEBAPP: "http://127.0.0.1:5000",
      TAPS_URL: "http://127.0.0.1:5000",
      PUSH_URL: "http://127.0.0.1:5000",
      METADATA: "http://127.0.0.1:5000",
      SQL_EDITOR_URL: "http://127.0.0.1:5000",
      OBSERVABILITY: "http://127.0.0.1:5000",
      WEBAPP: "http://127.0.0.1:5000",
      GIT_URL: "http://127.0.0.1:5000",
      DWH_URL: "http://127.0.0.1:5000",
      GOOGLE_CLIENT_ID:
        "1052331148909-rv54iiv6gc1h37h0tpus7iossj6dvddd.apps.googleusercontent.com",
    },
  },
};

export const config =
  process.env.NODE_ENV === "development" ? config_.dev : config_.prod;
