import React from "react";

// All pages that rely on 3rd party components (other than Bootstrap) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth
import Page404 from "./pages/auth/Page404";

//DWH
import Health from "./pages/dwh/Health";

//Admin
import Admin from "./pages/admin/admin";

//Taps
import Taps from "./pages/taps/Taps";

// SQL
import Editor from "./pages/sql/Editor";

//Jobs
import Overview from "./pages/jobs/Overview";
import Push from "./pages/jobs/Push";
import Observability from "./pages/observability";
// Protected routes
import ProtectedPage from "./pages/protected/ProtectedPage";

import APIVersion from "./pages/admin/api_version";

const routes = [
  {
    path: "taps",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "mytaps",
        element: <Taps />,
      },
    ],
  },
  {
    path: "sql",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "editor",
        element: <Editor />,
      },
    ],
  },
  {
    path: "jobs",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "overview",
        element: <Overview />,
      },
      {
        path: "push",
        element: <Push />,
      },
    ],
  },
  {
    path: "observability",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Observability />,
      },
    ],
  },
  {
    path: "admin",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "version",
        element: <APIVersion />,
      },
    ],
  },
  {
    path: "",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ProtectedPage />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
